import { combineReducers } from 'redux';
import userReducer from '../lib/userSlice';
import { apiSlice } from '../lib/ascertis-api';

const createRootReducer = () => combineReducers({
  user: userReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
});

export default createRootReducer;
