import 'isomorphic-fetch';
import * as Cookies from 'js-cookie';
import _ from 'lodash';
var moment = require('moment');

require('es6-promise').polyfill();

const queryString = require('query-string');

const HEADER_VARS = ['access-token', 'client', 'expiry', 'uid'];

/**
 * Add access token as a bearer token in accordance to RFC 6750
 *
 * @param {string} accessToken
 * @param {object} headers
 * @returns {object} New extended headers object, with Authorization property
 */
function addAuthorizationHeader(accessToken, headers) {
  return Object.assign({}, headers, {
    Authorization: `Bearer ${accessToken}`
  });
}

/**
 * Retrieves a stored var from the cookie
 *
 * @param {string} keyName
 * @returns {string} var in the key, unless it was null, in which case undefined is returned
 */
function storedVar(keyName) {
  const stored = Cookies.get(keyName);
  // Check to make sure it's not null, undefined, false, empty, or equal to the 'null' string
  if (stored && (stored !== 'null')) {
    return stored;
  }
  return undefined;
}

/**
 * Gets the current auth headers from our stored cookie
 *
 * @returns {object} Our authentication headers
 *
 * Example return:
 *  {
 *    access-token: bgINB4atOxd8SMNvtOTDxg
 *    client:       V7EN7LSRYAbpE_-c5PvRSw
 *    uid:          test@test.com
 *  }
 */

export const getAuthHeaders = () => {
  let baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // bust IE cache
  // https://stackoverflow.com/questions/5017454/make-ie-to-cache-resources-but-always-revalidate
  baseHeaders['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';

  HEADER_VARS.forEach((header, i) => {
    if (storedVar(header)) {
      baseHeaders[header] = storedVar(header);
    }
  });

  if (baseHeaders['access-token'] !== undefined) {
    baseHeaders = addAuthorizationHeader(baseHeaders['access-token'], baseHeaders);
  }

  return baseHeaders;
};

/**
 * Returns whether or not we have saved auth
 *
 * @returns {bool} Our authentication state
 *
 * Example return:
 *  false
 */
export function hasStoredAuth() {
  let hasAuth = true;

  HEADER_VARS.forEach((header, i) => {
    if (!storedVar(header)) {
      hasAuth = false;
    }
  });
  return hasAuth;
}

/**
 * Gets the current auth state from our stored cookie
 *
 * @returns {object} Our authentication state, for use with Redux
 *
 * Example return:
 *  {
 *    access-token: bgINB4atOxd8SMNvtOTDxg
 *    client:       V7EN7LSRYAbpE_-c5PvRSw
 *    uid:          test@test.com
 *  }
 */

export function getAuthState() {
  const baseState = {
    authenticated: false,
    endpoint: '',
    pending: false,
    error: false,
    user: undefined,
    userAgreement: undefined
  };

  let hasAuth = true;

  HEADER_VARS.forEach((header, i) => {
    if (!storedVar(header)) {
      hasAuth = false;
    }
  });

  // This has some errors in it where sometimes we end up
  // with a state that says it is authenticated, even with
  // a blank user
  baseState.authenticated = hasAuth;
  if (hasAuth) {
    const jsonData = Cookies.get('user', { path: '/' });
    if (jsonData) {
      baseState.user = JSON.parse(jsonData);
    }
  }

  return baseState;
}

/**
 * Stores the user details from authentication in our cookie
 *
 * @returns {object} The user object passed in
 */

export function storeUserDetails(user) {
  Cookies.set('user', user, { path: '/' });

  return user;
}

/**
 * Clears all of our cookie vars
 *
 * @returns true
 */

export function clearStoredVars() {
  Cookies.remove('access-token', { path: '/' });
  Cookies.remove('client', { path: '/' });
  Cookies.remove('expiry', { path: '/' });
  Cookies.remove('uid', { path: '/' });
  Cookies.remove('user', { path: '/' });

  return true;
}

export function printStoredVars() {
  console.log(`access-token: ${Cookies.get('access-token', { path: '/' })}`);
  console.log(`client: ${Cookies.get('client', { path: '/' })}`);
  console.log(`expiry: ${Cookies.get('expiry', { path: '/' })}`);
  console.log(`uid: ${Cookies.get('uid', { path: '/' })}`);
  console.log(`user: ${Cookies.get('user', { path: '/' })}`);
}

/**
 * Sets a timestamp var for when the session is started
 *
 * @returns {timestamp} returns and ISO string
 */
export function setSessionStart() {
  const currentTime =  moment().toISOString()
  Cookies.set('sessionStart', currentTime, { path: '/' });
  return currentTime;
}

/**
 * Gets the timestamp var for when the session was started
 *
 * @returns {timestamp} returns and ISO string
 */
export function getSessionStart() {
  return storedVar('sessionStart');
}

/**
 * Sets a timestamp var for when the session was last ended
 *
 * @returns {timestamp} returns and ISO string
 */
export function setSessionEnd() {
  const currentTime =  moment().toISOString();
  Cookies.set('sessionEnd', currentTime, { path: '/' });
  return currentTime;
}

/**
 * Gets the timestamp var for when the session was started
 *
 * @returns {timestamp} returns and ISO string
 */
export function getSessionEnd() {
  return storedVar('sessionEnd');
}

/**
 * Clears session management variables
 *
 * @returns {boolean} returns true
 */
export function clearSessionVars() {
  Cookies.remove('sessionStart', { path: '/' });
  Cookies.remove('sessionEnd', { path: '/' });
  return true
}

/**
 * Updates a field in our cookie if the value in the header is not empty
 *
 * @param {object} headers (our header object)
 * @param {string} fieldName (the field that we want set it our Cookie from our header)
 * @param {string} headerFieldName (the field from the header that holds the value)
 * @returns {object} The same response object, but with our credentials stripped out
 */
function updateCookieFieldFromHeader(headers, fieldName, headerFieldName) {
  const headerValue = _.get(headers, headerFieldName);

  if (headerValue.length > 0) {
    Cookies.set(fieldName, headerValue, { path: '/' });
  }
}

/**
 * Updates the auth credentials in our saved cookie
 *
 * @param {object} resp (our server response)
 * @returns {object} The same reponse object, but with our credentials stripped out
 */

export const updateAuthCredentials = (resp) => {
  const headers = resp.headers;
  if (_.get(headers, 'client')) {
    updateCookieFieldFromHeader(headers, 'access-token', 'accessToken');
    updateCookieFieldFromHeader(headers, 'client', 'client');
    updateCookieFieldFromHeader(headers, 'expiry', 'expiry');
    updateCookieFieldFromHeader(headers, 'uid', 'uid');
  }

  return resp;
}

/**
 * Parses the JWT data from a URL string
 *
 * @param {object} urlString (a query string from a URL)
 * @returns {boolean} true
 */

export const getAuthCredentialsFromUrl = (urlString) => {
  const parsed = queryString.parse(urlString);
  const urlHasCredentials = (parsed.token) && (parsed.client_id) && (parsed.expiry);

  if (urlHasCredentials) {
    Cookies.set('access-token', parsed.token, { path: '/' });
    Cookies.set('client', parsed.client_id, { path: '/' });
    Cookies.set('expiry', parsed.expiry, { path: '/' });
    Cookies.set('uid', parsed.uid, { path: '/' });
  }

  return true;
}
