import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import * as CONSTANTS from '../utils/constants';

import {
  getAuthHeaders,
  updateAuthCredentials,
} from '../utils/jwt-helpers';

export const API_GATEWAY_URL = CONSTANTS.AUTH_URL;
const authClient = axios.create({
  baseURL: API_GATEWAY_URL,
  timeout: 120000,
  headers: { 'Content-Type': 'application/json' }
  // TODO: This isn't working compared to the authRequestHandler
  // headers: getAuthHeaders()
});

const nonAuthClient = axios.create({
  baseURL: API_GATEWAY_URL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
});

const authRequestHandler = (request) => {
  request.headers = getAuthHeaders();
  return request;
};

const requestHandler = (request) => {
  return request;
};
const authResponseHandler = (response) => {
  updateAuthCredentials(response);
  return response;
};
const nonAuthResponseHandler = (response) => response;

/* eslint-disable no-console */
const requestErrorHandler = (error) => Promise.reject(error);
const responseErrorHandler = (error) => Promise.reject(error);
/* eslint-enable no-console */

authClient.interceptors.request.use(
  (request) => authRequestHandler(request),
  (error) => requestErrorHandler(error)
);

authClient.interceptors.response.use(
  (response) => authResponseHandler(response),
  (error) => responseErrorHandler(error)
);

nonAuthClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

nonAuthClient.interceptors.response.use(
  (response) => nonAuthResponseHandler(response),
  (error) => responseErrorHandler(error)
);

export const authApiClient = applyCaseMiddleware(authClient);
export const nonAuthApiClient = applyCaseMiddleware(nonAuthClient);
